import React from 'react';
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"
import HomeIntro from '../components/HomeIntro/HomeIntro'
import Quote from '../components/Quotes/Quotes'
import BestOfBothWorlds from '../components/BestOfBothWorlds/BestOfBothWorlds'
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
import Grid2 from '../components/Layout/Grid2/Grid2'
import GridItem from '../components/Layout/GridItem/GridItem'
import PageHeader from '../components/Layout/PageHeader/PageHeader'
import Paragraph1 from '../components/Type/Paragraph1'
import Heading2 from '../components/Type/Heading2'
import { Link } from "gatsby"

const ppsrPage = () => (

      <Layout>
        
        <SEO  title="" 
              description=""
        />

        <PageHeader
              title="Deposit"
              text=""
            />

        <SectionPageMargin >
            <Grid2>
              <GridItem>
                <Heading2>Payment Cancelled</Heading2>
                <Paragraph1>
                    Your card was not charged. Please email us at hello@summon.com.au with any questions or to arrange an alternative method of payment.
                </Paragraph1>
                <Paragraph1>
                    Are you selling a prestige car soon? Request a <Link to="https://value.summon.com.au//">Free Valuation</Link> or learn more 
                    about Summon and our <Link to="/sell-with-summon/">unique service</Link> for getting you the best price for your car.
                </Paragraph1>
              </GridItem>
              <GridItem>
                
              </GridItem>
            </Grid2>
        </SectionPageMargin>

        <HomeIntro />
      
        <Quote />

        <BestOfBothWorlds />

      </Layout>

    )

export default ppsrPage


